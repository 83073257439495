const menus = [
  {
    name: "$12,800",
    items: [
      "晶櫻燴龍蝦雙拼",
      "精選刺身盛合",
      "御品東坡肉",
      "清蒸海上鮮",
      "紅燒蹄筋烏參",
      "蒜蓉海大蝦",
      "干貝枸杞娃娃菜",
      "上海蟹粉菜飯",
      "帝王蟹米粉煲",
      "精緻甜點",
      "時令水果盤",
    ],
  },
  {
    name: "$16,800",
    items: [
      "澳龍富貴三拼",
      "精選刺身盛合",
      "上海肴豬腳",
      "清蒸深海魚",
      "御品東坡烏參",
      "香烤極地帝王蟹",
      "碧綠河蝦仁",
      "松露蝦仁炒飯",
      "巴西蘑菇雞湯",
      "精緻甜點",
      "時令水果盤",
    ],
  },
  {
    name: "$21,800",
    items: [
      "澳龍富貴四拼",
      "極上刺身盛合",
      "醬爆蟹年糕",
      "清蒸深海魚",
      "宮廷鮑魚烏參",
      "香烤極地帝王蟹",
      "XO醬干貝蘆筍蝦仁",
      "松露鮭魚卵炒飯",
      "砂鍋火烔雞濃湯",
      "精緻甜點",
      "時令水果盤",
    ],
  },
];

export default menus;
